import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  TreeDataNode,
  Upload,
  UploadProps,
} from 'antd';
import {
  DeleteOutlined,
  DeploymentUnitOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  InboxOutlined,
  PlusOutlined,
  SettingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { DiscoverListItem } from '../../../store/dataDiscover/IDataDiscover';
import {
  deleteDataDiscoverList,
  handleDataDiscoverList,
  updataSiderTree,
  updataSiderTreeById,
  updateBreadcrumbType,
  updateCurrentScene,
  updateDataDiscoverList,
} from '../../../store/dataDiscover/dataDiscoverReducer';
import { randomUUID } from 'crypto';
import { generateSecureRandomId } from '../../../utils';
import TreeSider from './TreeSelect';
import { conversationApi } from '../../../services/config';
import LocalGraphStore from '../../../components/ForceGraph/localGraphStore';
const { Dragger } = Upload;
const { confirm } = Modal;
const { Option } = Select;

const DiscoverSider: React.FC = () => {
  const [form] = Form.useForm();

  const discover = useSelector((state: RootState) => state.discover);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const siderData = useSelector((state: RootState) => state.discover.siderData);
  const currentScene = useSelector((state: RootState) => state.discover.currentScene);

  const store = LocalGraphStore.getInstance();

  const [isEditModalOpen, setIsEditModalOpen] = useState<any>({
    isOpen: false,
    title: '',
    selected: null,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [fileList, setFileList] = useState<any>([]);

  const showConfirm = (item: any) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(deleteDataDiscoverList(selectedItem as any));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedItem(null);
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    // showUploadList: false,
    action: 'https://stg.aidataexplorer.miniprogram.jp/api/test/test',
    onChange(info) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.map((file: any) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
          // file.name = generateSecureRandomId();
          file.id = file.uid;
          file.key = file.uid;
        }
        return file;
      });

      setFileList(newFileList);

      //   const { status } = info.file;
      //   if (status !== 'uploading') {
      //     setLoading(true);
      //   }
      //   if (status === 'done') {
      //     console.log(info);
      //     // setFileList([...fileList, ...info.fileList]);
      //     // info.fileList.forEach(file => {
      //     //   const data: DiscoverListItem = {
      //     //     id: file.uid,
      //     //     name: file.name,
      //     //   };

      //     //   //   dispatch(handleDataDiscoverList(data));
      //     // });
      //     setLoading(false);
      //   } else if (status === 'error') {
      //     message.error(`${info.file.name} file upload failed.`);
      //     setLoading(false);
      //   }
    },
    onRemove(e) {
      const { uid } = e;
      const _fileList = fileList.filter((i: { uid: string }) => i.uid !== uid);
      setFileList(_fileList);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const handleEditModalOk = () => {
    if (isEditModalOpen.selected) {
      const values = form.getFieldsValue();
      console.log(values);
      const payload = {
        ...isEditModalOpen.selected,
        title: values.title,
      };

      dispatch(updataSiderTreeById(payload));
      // const data: DiscoverListItem = {
      //   id: isEditModalOpen.selected.id,
      //   name: isEditModalOpen.title,
      // };
      // dispatch(updateDataDiscoverList(data));
    }
    handleEditModalCancel();
  };

  const handleEditModalCancel = () => {
    form.resetFields();
    setIsEditModalOpen({
      isOpen: false,
      title: '',
      selected: null,
    });
  };

  const handleDatabaseEdit = (item: any) => {};

  const handleFieldEdit = (item: any) => {
    setIsEditModalOpen({
      isOpen: true,
      title: item.title,
      selected: item,
    });
    form.setFieldsValue({
      title: item.title,
      sqlMode: 'STRICT_TRANS_TABLES',
      databaseType: 'MySQL',
    });
  };

  const uploadConfirm = () => {
    const params = {
      description:
        '従業員の役職管理、出勤記録、候補者情報、契約管理、給与管理など、人的資本に関わるデータを戦略的に管理し、組織価値を最大化するための領域。',
      key: 'jinteki_shihon_kanri',
      // name: '人的資本管理',
    };
    // const treeData = [
    //   {
    //     title: '経営管理',
    //     id: '1',
    //     key: '1',
    //     children: [{ title: '領域1', id: '1-1', key: '1-1' }],
    //   },
    //   {
    //     title: '人事採用',
    //     id: '2',
    //     key: '2',
    //     children: [{ title: '領域1', id: '2-1', key: '2-1' }],
    //   },
    // ];
    const _fileList = fileList.map((item: any) => ({
      name: item.name,
      uid: item.uid,
      id: item.id,
      url: item.url,
      title: item.name,
      key: 'jinteki_shihon_kanri',
      description:
        '従業員の役職管理、出勤記録、候補者情報、契約管理、給与管理など、人的資本に関わるデータを戦略的に管理し、組織価値を最大化するための領域。',
    }));

    const updatedSiderData = siderData.map((item: { children: any }, index: number) => {
      if (index === 0) {
        return {
          ...item,
          children: [..._fileList, ...item.children],
        };
      }
      return item;
    });

    // siderData[0].children = [..._fileList, ...siderData[0].children];

    dispatch(updataSiderTree(updatedSiderData));

    // // dispatch(handleDataDiscoverList(_fileList));
    setFileList([]);
  };

  const handleFieldTitleChange = (e: any) => {
    setIsEditModalOpen({
      ...isEditModalOpen,
      title: e.target.value,
    });
  };

  const handleGraphInit = async (item: any) => {
    if (currentScene !== item) {
      const resp = await conversationApi.getNetWork({ domain: item });
      store.setGraphData(resp);
      dispatch(updateCurrentScene(item));
      dispatch(updateBreadcrumbType('TABLE_RELATION'));
    }
  };

  return (
    <>
      <div className="tw-h-full tw-flex">
        <div className="tw-h-16 tw-w-full tw-justify-center">
          <Dragger fileList={fileList} {...props} accept=".txt, .csv">
            <Space
              className="tw-text-base tw-text-gray-500 hover:tw-text-[#1677ff] tw-font-bold tw-flex tw-justify-center	"
              direction="horizontal"
            >
              <InboxOutlined className="tw-text-3xl" />
              <div>領域作成</div>
            </Space>

            {/* <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p> */}
            {/* <p className=" tw-flex-shrink-0 tw-text-2xl">領域作成</p>
              <p className="ant-upload-text">
                クリックまたはファイルをこのエリアにドラッグしてアップロードしてください
              </p>
              <p className="ant-upload-hint">
                単一または複数のファイルのアップロードに対応しています。
              </p> */}
          </Dragger>

          <div className="tw-mt-5 tw-flex tw-justify-center">
            {fileList.length > 0 ? (
              <Button onClick={uploadConfirm} type="primary">
                確認
              </Button>
            ) : null}
          </div>

          {/* {discover.discoverList.length > 0 ? (
            <div className=" tw-flex tw-flex-col ">
              <Divider orientation="left">領域一覧</Divider>
              <Row gutter={[24, 24]}>
                {discover.discoverList.map((item, index) => (
                  <Col key={index} className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                    <Card
                      hoverable
                      //   actions={[
                      //     <EditOutlined onClick={() => handleFieldEdit(item)} key="edit" />,
                      //     // <SettingOutlined key="setting" />,
                      //     <DeleteOutlined onClick={() => showConfirm(item)} key="delete" />,
                      //   ]}
                    >
                      <div
                        onClick={() => handleGraphInit(item)}
                        className="tw-flex tw-flex-row tw-justify-between"
                      >
                        <Tooltip title={item.name}>
                          <div className="tw-text-xl tw-overflow-hidden tw-text-wrap tw-line-clamp-2 tw-break-all tw-text-ellipsis">
                            {item.name}
                          </div>
                        </Tooltip>
                        <Space
                          className="tw-text-base tw-ml-2  tw-text-gray-500"
                          direction="vertical"
                        >
                          <EditOutlined
                            onClick={e => {
                              e.stopPropagation();
                              handleFieldEdit(item);
                            }}
                            key="edit"
                          />
                          <DeleteOutlined
                            onClick={e => {
                              e.stopPropagation();
                              showConfirm(item);
                            }}
                            key="delete"
                          />
                        </Space>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null} */}
          <TreeSider
            handleSelecetd={handleGraphInit}
            handleFieldEdit={handleFieldEdit}
            showConfirm={showConfirm}
            handleDatabaseEdit={handleDatabaseEdit}
          ></TreeSider>
        </div>
      </div>

      <Modal
        title="この領域を削除ですか？"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="OK"
        cancelText="キャンセル"
      >
        <p>領域名：{selectedItem?.title}</p>
      </Modal>

      <Modal
        title="領域編集"
        open={isEditModalOpen.isOpen}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
      >
        <Form
          // layout="inline"
          form={form}
          labelCol={{
            span: 8,
          }}
          style={{
            maxWidth: 500,
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          <Form.Item name="title" label="領域名" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="databaseType" label="領域種類" rules={[{ required: true }]}>
            <Select allowClear>
              <Option value="Excel">Excel</Option>
              <Option value="Csv">Csv</Option>
              <Option value="MySQL">MySQL</Option>
              <Option value="PostgreSQL">PostgreSQL</Option>
              <Option value="MongoDB">MongoDB</Option>
            </Select>
          </Form.Item>
          <Form.Item name="sqlMode" label="SQLモード" rules={[{ required: true }]}>
            <Select allowClear>
              <Option value="STRICT_TRANS_TABLES">STRICT_TRANS_TABLES</Option>
              <Option value="ANSI_QUOTES">ANSI_QUOTES</Option>
            </Select>
          </Form.Item>
        </Form>

        {/* <Input defaultValue={isEditModalOpen.title} onChange={e => handleFieldTitleChange(e)} /> */}
      </Modal>
    </>
  );
};

export default React.memo(DiscoverSider);
