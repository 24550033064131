import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '..';
import { conversationApi } from '../../services/config';
import { updataSiderTree } from './dataDiscoverReducer';

const convertToTreeDataNode = (data: { category: string; domains: any[] }[]) => {
  return data.map((category: { category: string; domains: any[] }) => ({
    title: category.category,
    key: category.category.replace(/\s+/g, '_').toLowerCase(),
    // id: category.category,
    children: category.domains
      .filter(domain => domain.key !== 'jinteki_shihon_kanri') // 排除 jinteki_shihon_kanri
      .map(domain => ({
        title: domain.name,
        key: domain.key,
        // id: domain.key,
        description: domain.description,
      })),
  }));
};

export const getDomians = createAsyncThunk(
  'discover/getDomians',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await conversationApi.getDomians();
      if (response) {
        const treeData = convertToTreeDataNode(response);
        dispatch(updataSiderTree(treeData));
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNetWorkData = createAsyncThunk(
  'discover/getNetWorkData',
  async (domain: string, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState() as RootState; // RootState 是你的 state 的类型
      const { currentElementId } = state.discover; // 假设你想获取 scenario
      const response = await conversationApi.getNetWork({ domain });

      if (response) {
        // if (!conversationId) {
        //   dispatch(handleConversationId(response.conversation_id));
        // }
        // if (response.scene_key) {
        //   dispatch(handleScenario(response.scene_key));
        // }
        // let assistantMessage = {} as HistroyList;
        // assistantMessage.sender = 'assistant';
        // assistantMessage.content = response;
        // dispatch(handleReply(assistantMessage));
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
