import { formatNodeLabel } from '../../utils/index';
class G6StyleConfig {
  defaultNodeStyle = {
    size: 60,
  };

  taskNodeStyle = {
    fill: '#FFE0B2', // 节点填充颜色，橙色
    stroke: '#FA8C16', // 节点边框颜色，深橙色
    // fillOpacity: 0.35,
  };

  defaultNodeStateStyle = {
    selected: {
      stroke: '#85a5ff', // 节点边框颜色，深橙色
      lineWidth: 4, // 边框宽度
      shadowColor: '#85a5ff', // 阴影颜色与边框颜色一致
      shadowBlur: 10,
      'text-shape': {
        fontWeight: 'bold',
      },
    },
    inactive: {
      opacity: 0.1,
      fillOpacity: 0.1,
      'text-shape': {
        opacity: 0.1,
      },
    },
  };

  taskNodeStateStyle = {
    selected: {
      stroke: '#FA8C16', // 节点边框颜色，深橙色
      lineWidth: 4, // 边框宽度
      shadowColor: '#FA8C16', // 阴影颜色与边框颜色一致
      shadowBlur: 10,
      'text-shape': {
        fontWeight: 'bold',
      },
    },
    inactive: {
      fillOpacity: 0.1,
      opacity: 0.1,
      'text-shape': {
        opacity: 0.1,
      },
    },
  };

  defaultNodeLabelStyle = {};

  defaultEdgeStyle = {
    type: 'line',
    endArrow: true,
    lineAppendWidth: 5,
  };

  tableEdgeStyle = {
    stroke: '#85a5ff',
  };

  tableToTaskEdgeStyle = {
    stroke: '#b5b842',
  };

  tableToTaskEdgeStateStyle = {
    selected: {
      stroke: '#b5b842', // 节点边框颜色，深橙色
      // fill: '#85a5ff',
      lineWidth: 2, // 边框宽度
      shadowColor: '#b5b842', // 阴影颜色与边框颜色一致
      shadowBlur: 10,
      'text-shape': {
        fontWeight: 'bold',
        fill: '#000',
      },
    },
    inactive: {
      fillOpacity: 0.1,
      opacity: 0.1,
      'text-shape': {
        opacity: 0.1,
      },
    },
  };

  tableEdgeStateStyle = {
    selected: {
      stroke: '#85a5ff', // 节点边框颜色，深橙色
      // fill: '#85a5ff',
      lineWidth: 2, // 边框宽度
      shadowColor: '#85a5ff', // 阴影颜色与边框颜色一致
      shadowBlur: 10,
      'text-shape': {
        fontWeight: 'bold',
        fill: '#000',
      },
    },
    inactive: {
      fillOpacity: 0.1,
      opacity: 0.1,
      'text-shape': {
        opacity: 0.1,
      },
    },
  };

  taskEdgeStateStyle = {
    selected: {
      stroke: '#FF8C00', // 节点边框颜色，深橙色
      // fill: '#FF8C00',
      lineWidth: 2, // 边框宽度
      shadowColor: '#FF8C00', // 阴影颜色与边框颜色一致
      shadowBlur: 10,
      'text-shape': {
        fontWeight: 'bold',
      },
    },
    inactive: {
      fillOpacity: 0.1,
      opacity: 0.1,
      'text-shape': {
        opacity: 0.1,
      },
    },
  };

  taskEdgeStyle = {
    stroke: '#FF8C00',
  };

  defaultEdgeLabelStyle = {
    position: 'center',
    autoRotate: true,
    refY: 10,
    style: { fontSize: 8, fill: '#b3b3b3' },
  };

  applyNodeStyles(nodes) {
    return nodes.map(node => {
      node.oriLabel = node.label;
      if (node.type === 'task')
        return {
          ...node,
          ...this.defaultNodeStyle,
          cluster: node.type,
          style: this.taskNodeStyle,
          label: formatNodeLabel(node.label),
          stateStyles: this.taskNodeStateStyle,
        };
      return {
        ...node,
        ...this.defaultNodeStyle,
        cluster: node.type,
        label: formatNodeLabel(node.label),
        stateStyles: this.defaultNodeStateStyle,
      };
    });
  }

  _applyEdgeStateStyleByNode(sourceNode, targetNode) {
    if (sourceNode && targetNode) {
      if (sourceNode.type === 'table' && targetNode.type === 'table') {
        return this.tableEdgeStateStyle;
      } else if (
        (sourceNode.type === 'table' && targetNode.type === 'task') ||
        (sourceNode.type === 'task' && targetNode.type === 'table')
      ) {
        return this.tableToTaskEdgeStateStyle;
      } else if (sourceNode.type === 'task' && targetNode.type === 'task') {
        return this.taskEdgeStateStyle;
      }
    }
    // Default to taskEdgeStateStyle if no specific match
    return this.taskEdgeStateStyle;
  }

  _applyEdgeStyleByNode(sourceNode, targetNode) {
    if (sourceNode && targetNode) {
      if (sourceNode.type === 'table' && targetNode.type === 'table') {
        return { ...this.defaultEdgeStyle, ...this.tableEdgeStyle };
      } else if (
        (sourceNode.type === 'table' && targetNode.type === 'task') ||
        (sourceNode.type === 'task' && targetNode.type === 'table')
      ) {
        return { ...this.defaultEdgeStyle, ...this.tableToTaskEdgeStyle };
      } else if (sourceNode.type === 'task' && targetNode.type === 'task') {
        return { ...this.defaultEdgeStyle, ...this.taskEdgeStyle };
      }
    }
    // Default to taskEdgeStyle if no specific match
    return { ...this.defaultEdgeStyle, ...this.taskEdgeStyle };
  }

  applyEdgeStyles({ edges, nodes }) {
    // Create a map to count the number of edges between each pair of source and target nodes
    const edgeCountMap = {};

    edges.forEach(edge => {
      const key1 = `${edge.source}-${edge.target}`;
      const key2 = `${edge.target}-${edge.source}`;

      // Count both directions
      edgeCountMap[key1] = (edgeCountMap[key1] || 0) + 1;
      edgeCountMap[key2] = (edgeCountMap[key2] || 0) + 1;
    });

    return edges.map(edge => {
      const sourceNode = nodes.find(node => node.id === edge.source);
      const targetNode = nodes.find(node => node.id === edge.target);

      // Default style object based on node types
      const style = this._applyEdgeStyleByNode(sourceNode, targetNode);

      // Check if the edge is a loop
      const isLoop = sourceNode && targetNode && sourceNode.id === targetNode.id;

      // Check if there are multiple edges between source and target
      const hasMultipleEdges = edgeCountMap[`${edge.source}-${edge.target}`] > 1;

      return {
        ...edge,
        type: isLoop ? 'loop' : hasMultipleEdges ? 'quadratic' : edge.type, // Set type to 'quadratic' for multiple edges
        style,
        labelCfg: this.defaultEdgeLabelStyle,
        stateStyles: isLoop
          ? this.tableEdgeStateStyle
          : this._applyEdgeStateStyleByNode(sourceNode, targetNode),
      };
    });
  }

  applyElements({ nodes, edges }) {
    const _nodes = this.applyNodeStyles(nodes);
    const _egdes = this.applyNodeStyles(edges);
    return {
      node: _nodes,
      egdes: _egdes,
    };
  }
}

// 导出一个单例实例
const styleConfig = new G6StyleConfig();
export default styleConfig;
