import G6 from '@antv/g6';

class G6Singleton {
  constructor() {
    if (!G6Singleton.instance) {
      this.graph = null; // G6 图实例
      G6Singleton.instance = this;
    }
    return G6Singleton.instance;
  }

  // 初始化图表实例
  initGraph(container, options) {
    if (!this.graph) {
      this.graph = new G6.Graph({
        container,
        ...options,
      });
    }
    return this.graph;
  }

  // 获取图表实例
  getGraph() {
    return this.graph;
  }

  // 销毁图表实例
  destroyGraph() {
    if (this.graph) {
      this.graph.destroy();
      this.graph = null;
    }
  }
}

// 创建一个唯一的实例
const instance = new G6Singleton();

export default instance;
