import {
  configureStore,
  Dispatch,
  Middleware,
  MiddlewareAPI,
  PayloadAction,
} from '@reduxjs/toolkit';
import settingReducer from './settingReducer';

import conversationReducer from './conversation/chatHistoryReducer';
import dicoverReducer from './dataDiscover/dataDiscoverReducer';
import { loadingMiddleware } from './Middleware/loadingMiddleware';

export const store = configureStore({
  reducer: {
    setting: settingReducer,
    conversation: conversationReducer,
    discover: dicoverReducer,
    // 其他 reducers...
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(loadingMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
