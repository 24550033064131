import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SettingState {
  siderCollapsed: boolean;
  graphExpanded: boolean;
}

const initialState: SettingState = {
  siderCollapsed: false,
  graphExpanded: false,
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    handleSiderCollapse: (state, action: PayloadAction<boolean>) => {
      state.siderCollapsed = action.payload;
    },

    handlegraphExpand: (state, action: PayloadAction<boolean>) => {
      state.graphExpanded = action.payload;
    },
  },
});

export const { handleSiderCollapse, handlegraphExpand } = settingSlice.actions;

export default settingSlice.reducer;
