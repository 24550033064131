class G6Layout {
  fruchterman(graph) {
    return {
      type: 'fruchterman',
      gravity: 4, // 可选
      speed: 3, // 可选
      clustering: false, // 可选
      clusterGravity: 4, // 可选
      maxIteration: 2000, // 可选，迭代次数
      // workerEnabled: true, // 可选，开启 web-worker
      // gpuEnabled: true, // 可选，开启 GPU 并行计算，G6 4.0 支持

      tick: () => {
        graph.refreshPositions();
      },
    };
  }
  force() {
    return {
      type: 'force',
      preventOverlap: true,
      linkDistance: 150,
      nodeStrength: -150,
      collideStrength: 0.8,
      alpha: 0.3, // 初始迭代温度
      alphaDecay: 0.028, // 迭代温度衰减率
      alphaMin: 0.001,
      clustering: false,
      clusterNodeStrength: -150,
      clusterEdgeDistance: 200,
      clusterNodeSize: 80,
      clusterFociStrength: 1.2,
      nodeSpacing: 25,
      // linkDistance: (d: { source: { id: string } }) => {
      //   if (d.source.id === 'node0') {
      //     return 100;
      //   }
      //   return 30;
      // },
      // nodeStrength: (d: { isLeaf: any }) => {
      //   if (d.isLeaf) {
      //     return -50;
      //   }
      //   return -10;
      // },
      // edgeStrength: (d: { source: { id: string } }) => {
      //   if (d.source.id === 'node1' || d.source.id === 'node2' || d.source.id === 'node3') {
      //     return 0.7;
      //   }
      //   return 0.1;
      // },
    };
  }
}

const layoutConfig = new G6Layout();
export default layoutConfig;
