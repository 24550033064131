export const handleGraphRelation = (key: string, oriGraphData: any) => {
  let graphData;

  switch (key) {
    case 'TABLE_RELATION':
      graphData = filterNodesAndEdges(
        oriGraphData,
        ['table'],
        () => true // 只要连线两端都是 'table' 类型的节点
      );
      break;

    case 'TASK_DISCOVERY':
      graphData = filterNodesAndEdges(
        oriGraphData,
        ['table', 'task'],
        (_, sourceNode, targetNode) =>
          (sourceNode.type === 'table' || targetNode.type === 'table') &&
          !(sourceNode.type === 'task' && targetNode.type === 'task') // 确保连线至少一端为 'table'，且不两端都是 'task'
      );
      break;

    case 'TASK_RELATION':
      graphData = oriGraphData; // 全部数据
      break;

    default:
      return oriGraphData;
  }

  return {
    nodes: graphData.nodes,
    edges: graphData.edges,
  };
};

const filterNodesAndEdges = (
  oriGraphData: any,
  nodeTypes: string[],
  edgeCondition: (edge: any, source: any, target: any) => boolean
) => {
  const { nodes, edges } = oriGraphData;
  const filteredNodes = nodes.filter((node: any) => nodeTypes.includes(node.type));
  const filteredNodeIds = new Set(filteredNodes.map((node: any) => node.id));

  const filteredEdges = edges.filter((edge: any) => {
    const sourceNode = nodes.find((node: any) => node.id === edge.source);
    const targetNode = nodes.find((node: any) => node.id === edge.target);
    return (
      filteredNodeIds.has(edge.source) &&
      filteredNodeIds.has(edge.target) &&
      edgeCondition(edge, sourceNode, targetNode)
    );
  });

  return { nodes: filteredNodes, edges: filteredEdges };
};
