import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, ThemeConfig } from 'antd';
import { Provider } from 'react-redux';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const config: ThemeConfig = {
  cssVar: true,
  token: {
    // colorPrimary: 'rgb(0 0 0 0.06)',
  },
  components: {
    // Menu: {
    //   colorPrimary: 'rgb(82 82 91)',
    //   algorithm: true,
    // },
    // Button: {
    //   colorPrimary: '#00b96b',
    //   algorithm: true, // 启用算法
    // },
    // Input: {
    //   colorPrimary: '#eb2f96',
    //   algorithm: true, // 启用算法
    // },
  },
};

root.render(
  <React.Fragment>
    <ConfigProvider theme={config}>
      <Provider store={store}>
        <div className="App tw-h-full">
          <App />
        </div>
      </Provider>
    </ConfigProvider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
