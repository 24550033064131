import axios from 'axios';
import conversation from './modules/conversation';
const API_BASE_URL = 'https://stg.aidataexplorer.miniprogram.jp/api';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    // 你可以在这里添加认证令牌
    // const token = localStorage.getItem('token');
    // if (token) {
    //   config.headers['Authorization'] = `Bearer ${token}`;
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response) {
      // 处理响应错误
      console.error('Response error:', error.response.status);
    } else if (error.request) {
      // 处理请求错误
      console.error('Request error:', error.request);
    } else {
      // 处理其他错误
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export const conversationApi = conversation(axiosInstance);
