import { Space, Tooltip, Tree, TreeDataNode, TreeProps } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { DeleteOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';

const TreeSider: React.FC<any> = ({
  handleFieldEdit,
  showConfirm,
  handleSelecetd,
  handleDatabaseEdit,
}) => {
  const siderData = useSelector((state: RootState) => state.discover.siderData);

  const onSelect: TreeProps['onSelect'] = (selectedKeys: any, info: any) => {
    console.log('selected', info.node.key, info);
    handleSelecetd(info.node.key);
  };

  const onCheck: TreeProps['onCheck'] = (checkedKeys: any, info: any) => {
    console.log('onCheck', checkedKeys, info);
  };

  const transformData = (data: any[], parentKey = '0') => {
    return data.map((item, index) => {
      // const currentKey = `${parentKey}-${index}`;
      const transformedItem: any = {
        title: <div className="tw-font-bold tw-text-xl">{item.title}</div>,
        key: item.key,
        disabled: true,
      };

      // 如果有子节点
      if (item.children) {
        transformedItem.children = item.children.map(
          (
            child: {
              [x: string]: any;
            },
            childIndex: any
          ) => {
            const childKey = child.key;
            return {
              title: (
                <div className="tw-flex tw-justify-between">
                  <Tooltip title={child.description}>
                    <div className="tw-w-26 tw-mr-2 tw-text-base tw-line-clamp-1 ">
                      {child.title}
                    </div>
                  </Tooltip>

                  <Space size="small" direction="horizontal">
                    <EditOutlined
                      onClick={e => {
                        e.stopPropagation();
                        handleFieldEdit(child);
                      }}
                      key="edit"
                    />
                    {/* <SettingOutlined
                      onClick={e => {
                        e.stopPropagation();
                        handleDatabaseEdit(child);
                      }}
                      key="setting"
                    /> */}
                    <DeleteOutlined
                      onClick={e => {
                        e.stopPropagation();
                        showConfirm(child);
                      }}
                      key="delete"
                    />
                  </Space>
                </div>
              ),
              key: childKey,
            };
          }
        );
      }

      return transformedItem;
    });
  };

  return (
    <Tree
      autoExpandParent
      blockNode
      expandedKeys={['組織管理', '業務管理']}
      // defaultExpandAll={true}
      // autoExpandParent={true}
      // defaultExpandedKeys={['0-0-0', '0-0-1']}
      // defaultSelectedKeys={['0-0-0', '0-0-1']}
      // defaultCheckedKeys={['0-0-0', '0-0-1']}
      onSelect={onSelect}
      // onCheck={onCheck}
      treeData={transformData(siderData)}
    />
  );
};

export default React.memo(TreeSider);
