class LocalGraphStore {
  constructor(initialData = {}) {
    this.width = 0;
    this.height = 0;
    // Initialize properties
    this.showNodes = initialData.showNodes || [];
    this.showEdges = initialData.showEdges || [];
    this.curShowNodes = initialData.curShowNodes || [];
    this.curShowEdges = initialData.curShowEdges || [];
    this.nodes = initialData.nodes || [];
    this.edges = initialData.edges || [];
    this.nodesMap = new Map(initialData.nodeMap || []);
    this.edgesMap = new Map(initialData.edgesMap || []);
    this.curShowNodesMap = new Map(initialData.curShowNodesMap || []);
    this.graphData = [];
  }

  // Singleton instance
  static getInstance(initialData = {}) {
    if (!LocalGraphStore.instance) {
      LocalGraphStore.instance = new LocalGraphStore(initialData);
    }
    return LocalGraphStore.instance;
  }
  setWidth(width) {
    this.width = width;
  }

  setHeight(height) {
    this.width = height;
  }

  setGraphData(data) {
    this.graphData = data;
  }

  getGraphData() {
    return this.graphData;
  }

  getOriNodeById(id) {
    const { nodes } = this.graphData;
    return nodes.find(n => n.id === id);
  }

  updateOriItem(id, params) {
    const { nodes, edges } = this.graphData;
    // 更新节点
    const nodeIndex = nodes.findIndex(n => n.id === id);
    if (nodeIndex !== -1) {
      nodes[nodeIndex] = {
        ...nodes[nodeIndex],
        ...params,
      };
    }

    // 更新边（如果需要的话）
    const edgeIndex = edges.findIndex(e => e.id === id);
    if (edgeIndex !== -1) {
      edges[edgeIndex] = {
        ...edges[edgeIndex],
        ...params,
      };
    }
  }

  setEdgesMap(edges) {
    this.edgesMap = edges;
  }

  setNodeMap(nodes) {
    this.nodesMap = nodes;
  }

  setNodes(nodes) {
    this.nodes = nodes;
  }

  setEdges(edges) {
    this.edges = edges;
  }

  setShowNodes(nodes) {
    this.showNodes = nodes;
  }

  setShowEdges(edges) {
    this.showEdges = edges;
  }

  // Methods to interact with the store
  addNode(node) {
    this.nodes.push(node);
    this.nodesMap.set(node.id, node);
  }

  addEdge(edge) {
    this.edges.push(edge);
    this.edgesMap.set(edge.id, edge);
  }

  showNode(nodeId) {
    const node = this.nodesMap.get(nodeId);
    if (node) {
      this.curShowNodes.push(node);
      this.curShowNodesMap.set(nodeId, node);
    }
  }

  showEdge(edgeId) {
    const edge = this.edgesMap.get(edgeId);
    if (edge) {
      this.curShowEdges.push(edge);
    }
  }

  resetCurShow() {
    this.curShowNodes = [];
    this.curShowEdges = [];
    // this.curShowNodesMap.clear();
  }

  getNode(nodeId) {
    return this.nodesMap.get(nodeId) || null;
  }

  getEdge(edgeId) {
    return this.edgesMap.get(edgeId) || null;
  }

  getAllShowNodes() {
    return this.showNodes;
  }

  getAllShowEdges() {
    return this.showEdges;
  }

  getAllNodes() {
    return this.nodes;
  }

  getAllEdges() {
    return this.edges;
  }

  getAllNodesMap() {
    return this.nodesMap;
  }

  getAllEdgesMap() {
    return this.edgesMap;
  }

  getCurShowNodes() {
    return this.curShowNodes;
  }

  getCurShowEdges() {
    return this.curShowEdges;
  }
  getCurShowNodesMap() {
    return this.curShowNodesMap;
  }

  getWidth() {
    return this.width;
  }

  getHeight() {
    return this.height;
  }
}

// Export the singleton instance
export default LocalGraphStore;
