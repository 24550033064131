import { Dispatch, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { updateSidebarLoading } from '../dataDiscover/dataDiscoverReducer';

const isAsyncThunkAction = (action: any): boolean => {
  return (
    action.type.endsWith('/pending') ||
    action.type.endsWith('/fulfilled') ||
    action.type.endsWith('/rejected')
  );
};

export const loadingMiddleware: any =
  (store: MiddlewareAPI<Dispatch<PayloadAction<any>>, any>) =>
  (next: (arg0: any) => any) =>
  (action: { meta?: any; type: any; payload?: any }) => {
    if (isAsyncThunkAction(action)) {
      const state = store.getState() as RootState;
      console.log(store);
      // 根据 URL 控制不同的 loading 状态
      if (action.type.includes('/getDomians')) {
        if (action.type.endsWith('/pending')) {
          store.dispatch(updateSidebarLoading(true));
          //   state.discover.sidebarLoading = true;
          console.log('Sidebar loading...');
        } else if (action.type.endsWith('/fulfilled')) {
          store.dispatch(updateSidebarLoading(false));

          console.log('Sidebar loaded');
        } else if (action.type.endsWith('/rejected')) {
          store.dispatch(updateSidebarLoading(false));
          console.log('Sidebar request failed');
        }
      }
    }

    return next(action); // 继续执行后续中间件或 reducer
  };
