import { Axios, AxiosResponse } from 'axios';
import { ENDPOINTS } from '../endPoint';
import { ConversationPayload } from '../../store/conversation/IConversation';

export default function conversation(api: Axios) {
  return {
    getDomians(): Promise<any> {
      return api.get(ENDPOINTS.DOMAINS);
    },

    getNetWork(params: any): Promise<any> {
      return api.get(ENDPOINTS.NETWORK, { params });
    },
    graphData() {
      return api.get(ENDPOINTS.NEO4JDATA);
    },

    sendChat(params: any): Promise<ConversationPayload> {
      return api.post(ENDPOINTS.Chat, params, {
        params: {
          conversation_id: params.id,
          mode: params.mode,
        },
      });
    },
    getScenario(params: any): Promise<ConversationPayload> {
      return api.post(ENDPOINTS.SCENARIO, params, {
        params: {
          conversation_id: params.id,
        },
      });
    },
  };
}
