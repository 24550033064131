import { GraphData } from '@antv/g6';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { conversationApi } from '../../services/config';
import { RootState } from '..';
import { getScenarioResponse, submitMessageAndFetchResponse } from './conversationThunks';
import { ConversationPayload, ConversationState, HistroyList } from './IConversation';
// import { conversationApi } from '../services/modules/conversation';
import sheet from '../../assets/sheet.png';

// 通用的异步状态处理器
const isAsyncThunkAction = (action: any) => {
  return (
    action.type.endsWith('/pending') ||
    action.type.endsWith('/fulfilled') ||
    action.type.endsWith('/rejected')
  );
};

const initialState: ConversationState = {
  conversationId: null,
  id: null,
  loading: false,
  topic: '',
  scenario: null,
  graphData: [],
  questions: [],
  history: [
    { sender: 'user', content: { reply: 'Hello!' } },
    {
      sender: 'assistant',
      content: {
        reply: 'Hi, how can I help you?',
        image: {
          url: sheet,
          alt: 'Sample Image',
        },
      },
    },
    {
      sender: 'assistant',
      content: {
        reply: 'Hi, how can I help you?',
        form: [
          {
            label: '対象期間',
            type: 'dateRange',
            key: 'date_range',
            value: ['2023-10-01', '2023-10-31'],
          },
          {
            label: '費用項目',
            type: 'multiSelect',
            key: 'expense_items',
            options: [
              { label: '人件費', value: 'personnel', selected: true },
              { label: '販売費', value: 'selling', selected: true },
              { label: '一般管理費', value: 'administrative', selected: true },
              { label: '研究開発費', value: 'r_and_d', selected: true },
              { label: 'その他', value: 'others', selected: true },
            ],
          },
          {
            label: '表示形式',
            type: 'select',
            key: 'display_format',
            options: [
              { label: '合計のみ', value: 'total_only', selected: true },
              { label: '科目別詳細', value: 'detailed', selected: false },
            ],
          },
        ],
      },
    },
    {
      sender: 'assistant',
      content: {
        reply: 'Here is PieChart that you needed:',
        pieChart: [
          { name: 'Category A', value: 30 },
          { name: 'Category B', value: 70 },
          { name: 'Category C', value: 45 },
          { name: 'Category D', value: 85 },
        ],
      },
    },
    {
      sender: 'assistant',
      content: {
        reply: 'Here is BarChart that you needed:',
        barChart: [
          { name: 'Category A', value: 30 },
          { name: 'Category B', value: 70 },
          { name: 'Category C', value: 45 },
          { name: 'Category D', value: 85 },
          { name: 'Category E', value: 95 },
          { name: 'Category F', value: 25 },
        ],
      },
    },
    // { sender: 'assistant', message: "It's sunny and warm today." },
  ],
  files: [],
};

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    handleScenario: (state, action: PayloadAction<String>) => {
      state.scenario = action.payload;
    },

    updateGraphData: (state, action: PayloadAction<GraphData>) => {
      state.graphData = action.payload;
    },

    handleConversationId: (state, action: PayloadAction<string>) => {
      state.conversationId = action.payload;
    },

    handleQuestions: (state, action: PayloadAction<String[]>) => {
      state.questions = action.payload;
    },
    handleTopic: (state, action: PayloadAction<String>) => {
      state.topic = action.payload;
    },

    handleReply: (state, action: PayloadAction<HistroyList>) => {
      state.history.push(action.payload);
    },
    handleSumbit: (state, action: PayloadAction<String>) => {
      // if (state.history[0]) {
      //   // handleTopic(state.history[0].message);
      // }
      const item: any = {
        sender: 'user',
        content: {
          text: action.payload,
        },
      };
      state.history.push(item);
    },
  },
  // extraReducers: builder => {
  //   builder
  //     .addMatcher(
  //       action => isAsyncThunkAction(action) && action.type.endsWith('/pending'),
  //       state => {
  //         state.loading = true;
  //         console.log('Loading...');
  //       }
  //     )
  //     .addMatcher(
  //       action => isAsyncThunkAction(action) && action.type.endsWith('/fulfilled'),
  //       state => {
  //         state.loading = false;
  //         console.log('Loaded');
  //       }
  //     )
  //     .addMatcher(
  //       action => isAsyncThunkAction(action) && action.type.endsWith('/rejected'),
  //       (state, action: PayloadAction<string>) => {
  //         state.loading = false;
  //         console.error('Request failed:', action.payload);
  //       }
  //     );
  // },
});

export const {
  handleSumbit,
  handleTopic,
  handleQuestions,
  handleConversationId,
  handleReply,
  handleScenario,
} = conversationSlice.actions;

export default conversationSlice.reducer;
