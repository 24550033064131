import React from 'react';
import './styles/App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Graph from './pages/Graph/Graph';

const App: React.FC = () => (
  <Router>
    <Routes>
      {/* <Route path="/index/:id?" element={<Home />} /> */}
      <Route path="/graph/:id?" element={<Graph />} />
    </Routes>
  </Router>
);

export default App;
