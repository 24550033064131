import React, { useRef, useEffect, useState } from 'react';
import G6Singleton from './G6Singleton';
import styleConfig from './G6StyleConfig';
import G6Events from './G6Event';
import { createContextMenu, grid, minimap, snapLine, tooltip } from './G6Plugin';
import layoutConfig from './G6layout';
import DebounceSelect from '../../components/DebounceSelect';
import { Avatar, Flex, Grid, Layout, Space } from 'antd';
import DiscoverSider from './DiscoverSider';
import { LeftOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';
import logo from '../../assets/predictive.png';
import DiscoverButtons from './DiscoverButtons';
import { useDispatch } from 'react-redux';
import {
  updateContextMenuElementId,
  updateCurrentElementId,
} from '../../store/dataDiscover/dataDiscoverReducer';
import DiscoverDrawer from './DiscoverDrawer';
import { conversationApi } from '../../services/config';
import LocalGraphStore from '../../components/ForceGraph/localGraphStore';
import { handleGraphRelation } from './interactions';
import { getDomians } from '../../store/dataDiscover/discoverThunks';
import { AppDispatch } from '../../store';
import LogoSVG from './SvgLogo';
import G6 from '@antv/g6';

interface UserValue {
  label: string;
  value: string;
}
const { Header, Footer, Sider, Content } = Layout;

const Graph: React.FC = () => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<any>([]);
  const [list, setList] = useState<any>([]);
  const [hideSiderBar, setHideSiderBar] = useState<any>(false);

  const dispatch = useDispatch<AppDispatch>();
  const store = LocalGraphStore.getInstance();

  const handleContextMenu = (target: any, item: any) => {
    const { id } = item.getModel();
    if (target === 'OPEN_DESC') {
      dispatch(updateContextMenuElementId(id));
    }
  };

  useEffect(() => {
    if (!graphRef.current) return;
    const width = graphRef.current.scrollWidth;
    const height = graphRef.current.scrollHeight;
    const graph = G6Singleton.initGraph(graphRef.current, {
      width,
      height,
      autoFit: true,
      plugins: [minimap, tooltip, grid, createContextMenu(handleContextMenu)],
      animate: true,
      // fitView: true,
      // fitCenter: true,
      modes: {
        default: ['drag-canvas', 'drag-node', 'zoom-canvas'],
      },
      // 其他 G6 配置
    });

    graph.updateLayout(layoutConfig.force());

    // graph.updateLayout(layoutConfig.fruchterman(graph));

    // const _list = data.nodes.map(node => {
    //   return {
    //     label: node.label,
    //     value: node.id,
    //   };
    // });
    // setList(_list);

    return () => {
      G6Singleton.destroyGraph();
    };
  }, []);

  const initData = async () => {
    // const _resp = await conversationApi.getDomians();
    // console.log('_resp', _resp);

    dispatch(getDomians());

    // const resp = await conversationApi.getNetWork({});
    // store.setGraphData(resp);

    const graph = G6Singleton.getGraph()!;
    graph.render();
    // const data = handleGraphRelation('TABLE_RELATION', resp);

    // graph.data({
    //   nodes: styleConfig.applyNodeStyles(data.nodes),
    //   edges: styleConfig.applyEdgeStyles(data),
    // });
    // graph.render();
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    const graph = G6Singleton.getGraph();
    let g6Events: G6Events; // 声明 g6Events 变量
    if (graph) {
      g6Events = G6Events.getInstance(graph);
      g6Events.onClickNode(evt => {
        const model = evt.item.getModel();
        dispatch(updateCurrentElementId(model.id));
      });

      g6Events.onDbClickNode((evt: any) => {
        const model = evt.item.getModel();
        dispatch(updateContextMenuElementId(model.id));
      });

      g6Events.onClickEdge(evt => {
        console.log('Edge clicked:', evt.item);
      });

      g6Events.onClickCanvas(evt => {
        console.log('Canvas clicked');
      });

      g6Events.onMouseEnterNode(evt => {
        console.log('Mouse entered node:', evt.item);
      });

      g6Events.onMouseLeaveNode(evt => {
        console.log('Mouse left node:', evt.item);
      });
    }
    return () => {
      if (g6Events) {
        g6Events.clearAllEvents();
      }
    };
  }, []);

  const handleSelecetd = (value: any) => {
    const g6Events = G6Events.getInstanceOnly();
    const graph = G6Singleton.getGraph();
    const id = value.value;
    const item = graph?.findById(id);

    g6Events?.selecteElementWithNeighbors(item);
  };

  // return (
  //   <Flex wrap className="tw-w-full tw-h-full tw-flex-col">
  //     <div className="tw-flex tw-flex-col tw-h-full">
  //       <div className="tw-flex tw-flex-1">
  //         <div className="tw-w-1/5  tw-flex-1 tw-p-4 tw-pt-1">
  //           <div className="tw-h-14  tw-w-full tw-flex tw-justify-between tw-items-center">
  //             {/* <div className="tw-text-slate-600  tw-w-full tw-text-xl tw-font-bold tw-flex tw-items-center"> */}
  //             <div className="tw-text-slate-600 tw-w-full tw-flex tw-items-center tw-justify-around">
  //               <img className="tw-w-10" src={logo} alt="" />
  //               {/* <div className="tw-font-bold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-[#33235B] tw-via-[#D62229] tw-via-[#E97639] tw-via-[#792042] tw-to-[#33235B] tw-bg-logo-gradient tw-animate-animate-logo">
  //                 BI Agent- Data Discover
  //               </div> */}
  //               <div className="tw-text-base tw-font-bold">EasyBI Agent - Data Discover</div>
  //               {/* <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} /> */}
  //             </div>
  //             {/* </div> */}
  //           </div>
  //           <DiscoverSider />
  //           <DiscoverDrawer />
  //         </div>
  //         <div className="tw-w-4/5 tw-h-full tw-relative">
  //           <div className="tw-absolute tw-left-5 tw-top-5 tw-flex tw-items-center">
  //             <DiscoverButtons></DiscoverButtons>
  //             {/* <DebounceSelect
  //               className="tw-ml-5"
  //               allowClear
  //               value={value}
  //               onSelecetd={handleSelecetd}
  //               placeholder="シーンを探す"
  //               options={list}
  //               onChange={newValue => {
  //                 setValue(newValue);
  //               }}
  //               style={{ width: '100%' }}
  //             ></DebounceSelect> */}
  //           </div>
  //           <div className="tw-w-full tw-h-full" ref={graphRef}>
  //             {/* <div className="tw-absolute tw-bottom-5 tw-left-1/2 tw-transform tw--translate-x-1/2 ">
  //             <DiscoverButtons />
  //           </div> */}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </Flex>
  // );

  const handleSiderBarHide = () => {
    setHideSiderBar(!hideSiderBar);
    if (!graphRef.current) return;
    console.log(graphRef);
    // const width = graphRef.current.scrollWidth;
    // const height = graphRef.current.scrollHeight;
    // const graph = G6Singleton.getGraph();
    // const _grid = graph!.get('plugins')[2];
    // if (graph) {
    //   graph.changeSize(width, height);

    //   if (_grid) {
    //     graph.removePlugin(_grid);
    //   }
    //   setTimeout(() => {
    //     graph.addPlugin(new G6.Grid({}));

    //     graph!.refresh();
    //   }, 20);
    // }

    // graphRef.current.changeSize(width, height);
  };

  return (
    <div className="tw-flex tw-flex-col tw-h-screen tw-bg-gray-100">
      <DiscoverDrawer />
      <div className="tw-flex tw-items-center tw-justify-between tw-bg-white tw-shadow-md tw-p-4">
        <div className="tw-flex tw-items-center">
          <img src={logo} alt="Logo" className="tw-h-8 tw-mr-2" />
          <span className="tw-text-lg tw-font-bold tw-ml-2">EasyBI Agent - Data Discover</span>
        </div>
        <div className="tw-flex tw-items-center">
          <Avatar size="small" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
          <span className="tw-text-sm tw-ml-2">山田 太郎</span>
        </div>
      </div>
      <div className="tw-flex tw-flex-grow tw-h-0">
        {hideSiderBar ? null : (
          <div className="tw-w-2/12 tw-py-2 tw-pl-2 tw-flex tw-flex-col tw-space-y-2 ">
            <div className="tw-bg-white tw-shadow-md tw-rounded-lg tw-p-4 tw-flex tw-flex-col tw-h-full">
              <DiscoverSider />
            </div>
          </div>
        )}

        {/* <div className="tw-text-gray-400 tw-text-xs hover:tw-opacity-50 tw-cursor-pointer tw-rounded-full tw-flex tw-items-center tw-justify-center">
          {hideSiderBar ? (
            <RightOutlined onClick={handleSiderBarHide} />
          ) : (
            <LeftOutlined onClick={handleSiderBarHide} />
          )}
        </div> */}
        <div
          className={` ${
            hideSiderBar ? 'tw-w-full' : 'tw-w-10/12'
          } tw-flex tw-flex-col tw-relative tw-py-2 tw-px-2 tw-space-y-2`}
        >
          <div className="tw-bg-white tw-shadow-md tw-rounded-lg tw-flex tw-flex-col tw-h-full">
            <div className="tw-p-4 tw-h-full tw-z-[99]">
              <div
                className="tw-w-full tw-h-full tw-border tw-box-border tw-border-slate-100 tw-border-solid"
                ref={graphRef}
              ></div>
            </div>
            <div className="tw-absolute tw-z-[999]  tw-left-8 tw-top-8 tw-flex tw-items-center">
              <DiscoverButtons></DiscoverButtons>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Graph);
