import { Breadcrumb, Button, Card, Divider } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import { AppstoreAddOutlined, DoubleRightOutlined, DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space, Typography, theme } from 'antd';
import LocalGraphStore from '../../../components/ForceGraph/localGraphStore';
import G6Singleton from '../G6Singleton';
import styleConfig from '../G6StyleConfig';
import { handleGraphRelation } from '../interactions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { updateBreadcrumbType } from '../../../store/dataDiscover/dataDiscoverReducer';

const { useToken } = theme;

const buttonList = [
  {
    key: 1,
    label: 'テーブルリレーション',
    action: 'TABLE_RELATION',
    descriptions: '',
  },
  {
    key: 2,
    label: 'タスクディスカバリー',
    action: 'TASK_DISCOVERY',
    descriptions: '',
  },
  {
    key: 3,
    label: 'タスクリレーション',
    action: 'TASK_RELATION',
    descriptions: '',
  },
  {
    key: 4,
    label: 'タスクセントリック',
    action: 'TASK_CENTRIC',
    descriptions: '',
  },
  {
    key: 5,
    label: 'テーブルセントリック',
    action: 'TABLE_CENTRIC',
    descriptions: '',
  },
  {
    key: 6,
    label: 'タスクコネクション',
    action: 'TASK_CONNECTION',
    descriptions: '',
  },
  {
    key: 7,
    label: 'データアイソレーション',
    action: 'DATA_ISOLATION',
    descriptions: '',
  },
];

const breadcrumbList = () => {
  return [
    {
      title: 'データ対象',
      key: 'TABLE_RELATION',
      descriptions: '',
    },
    {
      type: 'separator', // Must have
      key: 'separator1',
      separator: (key: string) => <DoubleRightOutlined key={key} />,
    },
    {
      title: 'タスク対象',
      key: 'TASK_DISCOVERY',
      descriptions: '',
    },
    {
      type: 'separator', // Must have
      key: 'separator',
      separator: (key: string) => <DoubleRightOutlined key={key} />,
    },
    {
      title: 'タスクリレーション',
      key: 'TASK_RELATION',
      descriptions: '',
    },
  ];
};
const DiscoverButtons: React.FC = () => {
  const { token } = useToken();
  const store = LocalGraphStore.getInstance();
  const breadcrumbType = useSelector((state: RootState) => state.discover.breadcrumbType);
  const currentScene = useSelector((state: RootState) => state.discover.currentScene);

  const dispatch = useDispatch();
  // const [selectItem, setSelectItem] = useState<any>('TABLE_RELATION');

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  useEffect(() => {
    if (breadcrumbType) {
      const oriGraphData = store.getGraphData();
      const graph = G6Singleton.getGraph()!;
      if (oriGraphData.nodes && oriGraphData.nodes.length > 0) {
        const data = handleGraphRelation(breadcrumbType, oriGraphData);
        graph.clear();
        graph.changeData({
          nodes: styleConfig.applyNodeStyles(data.nodes),
          edges: styleConfig.applyEdgeStyles({ edges: data.edges, nodes: data.nodes }),
        });
      }
    }
  }, [breadcrumbType, currentScene]);

  const handleBreadcrumbClick = (item: any) => {
    const { key } = item;
    // setSelectItem(key);
    dispatch(updateBreadcrumbType(key));

    // if (key === 'TABLE_RELATION') {
    //   const tableNodes = nodes.filter((node: any) => node.type === 'table');
    //   const tableNodeIds = new Set(
    //     nodes
    //       .filter((node: { type: string }) => node.type === 'table')
    //       .map((node: { id: any }) => node.id)
    //   );

    //   // 获取所有与 type 为 'table' 节点相关的连线
    //   const tableEdges = edges.filter(
    //     (edge: any) => tableNodeIds.has(edge.source) && tableNodeIds.has(edge.target)
    //   );

    //   graph.changeData({
    //     nodes: styleConfig.applyNodeStyles(tableNodes),
    //     edges: styleConfig.applyEdgeStyles({ edges: tableEdges, nodes: tableNodes }),
    //   });
    // }
    // if (key === 'TASK_DISCOVERY') {
    //   const tableTaskNodes = nodes.filter(
    //     (node: any) => node.type === 'table' || node.type === 'task'
    //   );

    //   const taskAndTableNodeIds = new Set(
    //     nodes
    //       .filter((node: { type: string }) => node.type === 'task' || node.type === 'table')
    //       .map((node: { id: any }) => node.id)
    //   );

    //   // 过滤出符合条件的连线：至少有一个端点为 'table'，并且两端不全是 'task'
    //   const taskAndTableEdges = edges.filter((edge: { source: unknown; target: unknown }) => {
    //     const sourceNode = nodes.find((node: { id: any }) => node.id === edge.source);
    //     const targetNode = nodes.find((node: { id: any }) => node.id === edge.target);

    //     // 检查 source 和 target 是否在 taskAndTableNodeIds 中
    //     const isSourceTaskOrTable = taskAndTableNodeIds.has(edge.source);
    //     const isTargetTaskOrTable = taskAndTableNodeIds.has(edge.target);

    //     // 确保连线的两端至少有一个是 'table'，并且不是两端都是 'task'
    //     return (
    //       isSourceTaskOrTable &&
    //       isTargetTaskOrTable &&
    //       (sourceNode.type === 'table' || targetNode.type === 'table') &&
    //       !(sourceNode.type === 'task' && targetNode.type === 'task')
    //     );
    //   });
    //   graph.changeData({
    //     nodes: styleConfig.applyNodeStyles(tableTaskNodes),
    //     edges: styleConfig.applyEdgeStyles({ edges: taskAndTableEdges, nodes: tableTaskNodes }),
    //   });
    // }
    // if (key === 'TASK_RELATION') {
    //   graph.changeData({
    //     nodes: styleConfig.applyNodeStyles(nodes),
    //     edges: styleConfig.applyEdgeStyles({ edges: edges, nodes: nodes }),
    //   });
    // }
  };

  return (
    <Space className="tw-flex tw-items-center tw-text-xl tw-font-bold tw-text-slate-400 ">
      {breadcrumbList().map((item, index) => {
        if (item.type === 'separator') {
          return item.separator(index.toString());
        }
        return (
          <div
            onClick={() => handleBreadcrumbClick(item)}
            className={`hover:tw-text-[#1677ff] tw-cursor-pointer ${
              breadcrumbType === item.key ? 'tw-text-[#1677ff]' : null
            }`}
            key={index}
          >
            {item.title}
          </div>
        );
      })}
    </Space>
    // <Breadcrumb separator=">" items={breadcrumbList(handleBreadcrumbClick)}></Breadcrumb>
    // <Dropdown
    //   menu={{
    //     items: buttonList,
    //     selectable: true,
    //     defaultSelectedKeys: ['3'],
    //   }}
    //   dropdownRender={menu => (
    //     <div style={contentStyle}>
    //       <div className="tw-text-center tw-p-2 tw-font-bold">ビュー変更</div>
    //       <Divider style={{ margin: 0 }} />
    //       {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
    //     </div>
    //   )}
    // >
    //   <Typography.Link>
    //     <Space>
    //       <AppstoreAddOutlined className="tw-text-lg tw-text-slate-700" />
    //     </Space>
    //   </Typography.Link>
    // </Dropdown>

    // tw-bg-white tw-border tw-border-solid tw-rounded tw-border-[#d9d9d9]
    // <div className="tw-p-2">
    //   {buttonList.map((item, index) => (
    //     <Button className="tw-m-1 tw-rounded-full" key={index} color="default">
    //       {item.title}
    //     </Button>
    //   ))}
    // </div>
  );
};

export default React.memo(DiscoverButtons);
