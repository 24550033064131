import { Graph } from '@antv/g6';
type EventHandler = (evt: any) => void;

class G6Events {
  private static instance: G6Events; // 单例实例
  private graph: Graph;
  private registeredEvents: { eventName: string; handler: EventHandler }[] = []; // 记录事件

  private constructor(graph: Graph) {
    this.graph = graph;
  }

  // 获取单例实例
  public static getInstance(graph: Graph): G6Events {
    if (!G6Events.instance) {
      G6Events.instance = new G6Events(graph);
    }
    return G6Events.instance;
  }

  public static getInstanceOnly(): G6Events | undefined {
    return G6Events.instance;
  }

  // 添加事件监听器
  private addEvent(eventName: string, handler: EventHandler) {
    this.graph.on(eventName, handler);
    this.registeredEvents.push({ eventName, handler }); // 记录事件
  }

  // 移除事件监听器
  private removeEvent(eventName: string, handler: EventHandler) {
    this.graph.off(eventName, handler);
    this.registeredEvents = this.registeredEvents.filter(
      event => event.eventName !== eventName || event.handler !== handler
    );
  }

  onDbClickNode(handler?: EventHandler) {
    this.addEvent('node:dblclick', evt => {
      if (handler) handler(evt);
    });
  }

  // 点击节点事件，并应用高亮样式
  onClickNode(handler?: EventHandler) {
    this.addEvent('node:click', evt => {
      this.clearAllHighlight();
      this.selecteElementWithNeighbors(evt.item);
      // this.graph.setItemState(evt.item, 'selected', true);
      if (handler) handler(evt);
    });
  }

  // 点击边事件，并应用高亮样式
  onClickEdge(handler?: EventHandler) {
    this.addEvent('edge:click', evt => {
      this.clearAllHighlight();
      this.selecteElementWithNeighbors(evt.item);

      // this.graph.setItemState(evt.item, 'selected', true);
      if (handler) handler(evt);
    });
  }

  // 点击画布事件，用于清除所有高亮
  onClickCanvas(handler?: EventHandler) {
    this.addEvent('canvas:click', evt => {
      const minimap = this.graph.get('plugins')[0];
      // 在需要刷新 minimap 的地方调用 updateCanvas
      if (minimap) {
        minimap.handleUpdateCanvas();
      }
      this.clearAllHighlight();
      if (handler) handler(evt);
    });
  }

  onMouseEnterNode(handler?: EventHandler) {
    this.addEvent('node:mouseenter', evt => {
      if (handler) handler(evt);
    });
  }

  // 鼠标移出节点事件，恢复样式
  onMouseLeaveNode(handler?: EventHandler) {
    this.addEvent('node:mouseleave', evt => {
      if (handler) handler(evt);
    });
  }

  clearAllEvents() {
    this.registeredEvents.forEach(({ eventName, handler }) => {
      this.graph.off(eventName, handler);
    });
    this.registeredEvents = []; // 清空事件记录
  }

  selecteElementWithNeighbors(item: any) {
    const nodes = this.graph.getNodes();
    const edges = this.graph.getEdges();
    nodes.forEach(node => {
      this.graph.setItemState(node, 'inactive', true);
    });

    edges.forEach(edge => {
      this.graph.setItemState(edge, 'inactive', true);
    });

    this.graph.clearItemStates(item, ['inactive']);
    this.graph.setItemState(item, 'selected', true);
    // 判断 item 是节点还是边
    if (item.getType() === 'node') {
      // 如果是节点，清除该节点的 inactive 状态并设置为 selected
      this.graph.clearItemStates(item, ['inactive']);
      this.graph.setItemState(item, 'selected', true);

      // 获取相邻的边和节点
      const neighborEdges = item.getEdges();
      const neighborNodes = item.getNeighbors();

      neighborEdges.forEach((edge: any) => {
        this.graph.clearItemStates(edge, ['inactive']);
        // this.graph.setItemState(edge, 'selected', true);
      });

      neighborNodes.forEach((node: any) => {
        this.graph.clearItemStates(node, ['inactive']);
        // this.graph.setItemState(node, 'selected', true);
      });
    } else if (item.getType() === 'edge') {
      // 如果是边，清除该边的 inactive 状态并设置为 selected
      this.graph.clearItemStates(item, ['inactive']);
      this.graph.setItemState(item, 'selected', true);

      // 获取该边连接的两个端点节点
      const sourceNode = item.getSource();
      const targetNode = item.getTarget();

      // 清除这两个节点的 inactive 状态并设置为 selected
      this.graph.clearItemStates(sourceNode, ['inactive']);
      // this.graph.setItemState(sourceNode, 'selected', true);

      this.graph.clearItemStates(targetNode, ['inactive']);
      // this.graph.setItemState(targetNode, 'selected', true);
    }
  }

  private clearAllHighlight() {
    const nodes = this.graph.getNodes();
    const edges = this.graph.getEdges();

    nodes.forEach(node => {
      this.graph.clearItemStates(node, ['selected', 'inactive']);
    });

    edges.forEach(edge => {
      this.graph.clearItemStates(edge, ['selected', 'inactive']);
    });
  }
}
export default G6Events;
