import G6, { Graph } from '@antv/g6';

export const tooltip = new G6.Tooltip({
  offsetX: 10,
  offsetY: 0,
  // v4.2.1 起支持 fixToNode，tooltip 相对于节点固定位置
  fixToNode: [1, 0],
  // the types of items that allow the tooltip show up
  // 允许出现 tooltip 的 item 类型
  itemTypes: ['node', 'edge'],
  // custom the tooltip's content
  // 自定义 tooltip 内容
  shouldBegin: (e: any) => {
    const model = e.item.getModel();
    return !!model.description; // 如果 description 存在，返回 true，否则返回 false
  },

  // 自定义 tooltip 内容
  getContent: (e: any) => {
    const model = e.item.getModel();
    const outDiv = document.createElement('div');
    outDiv.style.width = '126px';
    outDiv.innerHTML = `
      <div class="tw-font-bold"> ${model.oriLabel}</div>
      <div> ${model.description ? `<div>${model.description}</div>` : ''}</div>
     `;

    return outDiv;
  },
});

export const snapLine = new G6.SnapLine({
  line: {
    stroke: '#ccc',
    lineWidth: 1,
  },
  itemAlignType: true,
});

export const grid = new G6.Grid({});

export const minimap = new G6.Minimap({
  size: [150, 100],
  viewportClassName: 'my-minimap',
});

export const createContextMenu = (callback: Function) => {
  return new G6.Menu({
    offsetX: 6,
    offsetY: 10,
    itemTypes: ['node'],
    getContent(e: any) {
      console.log('eee', e);
      const outDiv = document.createElement('div');
      const model = e.item.getModel();
      // <div class="tw-mb-2 hover:tw-text-[#1677ff]" data-action="TASK_CENTRIC">タスクセントリック</div>
      // <div class="tw-mb-2 hover:tw-text-[#1677ff]" data-action="TABLE_CENTRIC">テーブルセントリック</div>
      // <div class="tw-mb-2 hover:tw-text-[#1677ff]" data-action="TASK_CONNECTION">タスクコネクション</div>
      outDiv.innerHTML = `
        <div class="tw-cursor-pointer tw-text-base">
          <div class="hover:tw-text-[#1677ff]" data-action="OPEN_DESC">詳細を見る</div>
        </div>
        `;
      return outDiv;
    },
    handleMenuClick(target, item) {
      const action = target.getAttribute('data-action');
      if (callback) {
        callback(action, item); // 调用传入的回调函数
      }
    },
  });
};
