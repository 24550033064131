import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
  updateContextMenuElementId,
  updateCurrentElementId,
} from '../../../store/dataDiscover/dataDiscoverReducer';
import G6Singleton from '../G6Singleton';
import { convertFieldsArrayToObject } from '../../../utils';
import LocalGraphStore from '../../../components/ForceGraph/localGraphStore';
import { formatNodeLabel } from '../../../utils';

const DiscoverDrawer: React.FC = () => {
  const [form] = Form.useForm();
  const store = LocalGraphStore.getInstance();

  const discover = useSelector((state: RootState) => state.discover);
  const dispatch = useDispatch();
  const [drawInfo, setDrawInfo] = useState<any>({});

  const onClose = () => {
    dispatch(updateContextMenuElementId(null));
  };

  const onConfirm = () => {
    const values = form.getFieldsValue();
    const updatedData = Object.keys(values).reduce((acc: any, key: any) => {
      if (key.endsWith('_description')) {
        const fieldKey = key.replace('_description', ''); // 去掉 '_description' 后缀
        acc[fieldKey] = values[key]; // 获取描述字段的值
      }
      return acc;
    }, {});

    const formattedArray = Object.entries(updatedData).map(([key, value]) => ({
      [key]: value,
    }));
    const _params = {
      label: values.label,
      description: values.description,
      field_details: formattedArray,
    };

    store.updateOriItem(discover.contextMenuElementId, _params);

    const graph = G6Singleton.getGraph();
    if (discover.contextMenuElementId) {
      graph?.updateItem(discover.contextMenuElementId, {
        description: values.description,
        label: formatNodeLabel(values.label),
        oriLabel: values.label,
      });
    }

    dispatch(updateContextMenuElementId(null));
  };

  useEffect(() => {
    if (discover.contextMenuElementId) {
      const graph = G6Singleton.getGraph();
      const element = graph?.findById(discover.contextMenuElementId);
      const model = element?.getModel() as any;

      const oriNode = store.getOriNodeById(model.id);
      setDrawInfo({
        title: model?.oriLabel,
        type: model?.type,
        fields: convertFieldsArrayToObject(oriNode.field_details),
      });
      console.log(convertFieldsArrayToObject(oriNode.field_details));
      form.setFieldsValue({
        label: model?.oriLabel,
        description: model?.description,
      });
    }
  }, [discover.contextMenuElementId, form]);
  return (
    <>
      <Drawer
        title={drawInfo.title}
        width={720}
        onClose={onClose}
        // maskClosable={false}
        open={!!discover.contextMenuElementId}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>取消</Button>
            <Button onClick={onConfirm} type="primary">
              保存
            </Button>
          </Space>
        }
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              {drawInfo.type === 'table' ? (
                <Form.Item name="label" label="テーブル名" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item name="label" label="タスク名" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              )}
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="説明"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
          {drawInfo.fields &&
            Object.entries(drawInfo.fields).map(([key, value]: any) => (
              <Row gutter={16} key={key}>
                <Col span={12}>
                  <Form.Item
                    name={`${key}_field`}
                    label="フィールド"
                    initialValue={key} // 使用 initialValue 设定值
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={`${key}_description`}
                    label="説明"
                    initialValue={value} // 使用 initialValue 设定值
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          <Row gutter={16}>
            {/* <Col span={12}>
              <Form.Item
                name="owner"
                label="Owner"
                rules={[{ required: true, message: 'Please select an owner' }]}
              >
                <Select placeholder="Please select an owner">
                  <Option value="xiao">Xiaoxiao Fu</Option>
                  <Option value="mao">Maomao Zhou</Option>
                </Select>
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: 'Please choose the type' }]}
              >
                <Select placeholder="Please choose the type">
                  <Option value="private">Private</Option>
                  <Option value="public">Public</Option>
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={16}>
            {/* <Col span={12}>
              <Form.Item
                name="approver"
                label="Approver"
                rules={[{ required: true, message: 'Please choose the approver' }]}
              >
                <Select placeholder="Please choose the approver">
                  <Option value="jack">Jack Ma</Option>
                  <Option value="tom">Tom Liu</Option>
                </Select>
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                name="dateTime"
                label="DateTime"
                rules={[{ required: true, message: 'Please choose the dateTime' }]}
              >
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  getPopupContainer={trigger => trigger.parentElement!}
                />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default React.memo(DiscoverDrawer);
