import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataDiscoverState, DiscoverListItem, ITreeDataNode } from './IDataDiscover';
import { GraphData } from '@antv/g6';
import { TreeDataNode } from 'antd';

const initialState: DataDiscoverState = {
  discoverList: [],
  currentElementId: null,
  currentScene: null,
  contextMenuElementId: null,
  graphData: [],
  breadcrumbType: null,
  sidebarLoading: false,
  siderData: [
    // {
    //   title: '経営管理',
    //   id: '1',
    //   key: '1',
    //   children: [{ title: '領域1', id: '1-1', key: '1-1' }],
    // },
    // {
    //   title: '人事採用',
    //   id: '2',
    //   key: '2',
    //   children: [{ title: '領域1', id: '2-1', key: '2-1' }],
    // },
  ],
};

const deleteNodeByKey = (nodes: any[], key: string): any[] => {
  return nodes
    .map((node: any) => {
      if (node.key === key) {
        return null; // 如果匹配到 key，删除该节点
      }
      if (node.children) {
        return { ...node, children: deleteNodeByKey(node.children, key) };
      }
      return node;
    })
    .filter((node: any) => node !== null); // 过滤掉被删除的节点
};

const updateNodeByKey = (nodes: any, key: string, newData: ITreeDataNode): ITreeDataNode[] => {
  return nodes.map((node: any) => {
    if (node.key === key) {
      return { ...node, ...newData }; // 更新匹配的节点
    }
    if (node.children) {
      return { ...node, children: updateNodeByKey(node.children, key, newData) };
    }
    return node;
  });
};

export const dataDiscoverSlice = createSlice({
  name: 'dataDiscover',
  initialState,
  reducers: {
    updateSidebarLoading: (state, action: PayloadAction<boolean>) => {
      state.sidebarLoading = action.payload;
    },
    updataSiderTreeById: (state, action: PayloadAction<ITreeDataNode>) => {
      state.siderData = updateNodeByKey(
        state.siderData,
        action.payload.key.toString(),
        action.payload
      );
    },
    updataSiderTree: (state, action: PayloadAction<ITreeDataNode[]>) => {
      state.siderData = [...action.payload];
    },
    updateCurrentScene: (state, action: PayloadAction<string | null>) => {
      state.currentScene = action.payload;
    },
    updateBreadcrumbType: (state, action: PayloadAction<string | null>) => {
      state.breadcrumbType = action.payload;
    },
    updateGraphData: (state, action: PayloadAction<GraphData>) => {
      state.graphData = action.payload;
    },
    updateContextMenuElementId: (state, action: PayloadAction<string | null>) => {
      state.contextMenuElementId = action.payload;
    },
    updateCurrentElementId: (state, action: PayloadAction<string | null>) => {
      state.currentElementId = action.payload;
    },
    handleDataDiscoverList: (state, action: PayloadAction<DiscoverListItem[]>) => {
      state.discoverList = [...state.discoverList, ...action.payload];
    },
    updateDataDiscoverList: (state, action: PayloadAction<DiscoverListItem>) => {
      const index = state.discoverList.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.discoverList[index] = action.payload;
      }
    },
    deleteDataDiscoverList: (state, action: PayloadAction<DiscoverListItem>) => {
      state.siderData = deleteNodeByKey(state.siderData, action.payload.key.toString());
    },
  },
});

export const {
  handleDataDiscoverList,
  updateDataDiscoverList,
  deleteDataDiscoverList,
  updateCurrentElementId,
  updateGraphData,
  updateContextMenuElementId,
  updateBreadcrumbType,
  updataSiderTree,
  updataSiderTreeById,
  updateCurrentScene,
  updateSidebarLoading,
} = dataDiscoverSlice.actions;

export default dataDiscoverSlice.reducer;
